import React, { useImperativeHandle, useRef } from 'react'
import { withPrefix, Link, navigate } from 'gatsby'
import styled from 'styled-components'
import * as styles from './Menu.module.css'
import Img from 'gatsby-image'

const DivBgImg2Parent = styled.div`
  width: 300px;
  height: 300px;
  margin: 10px;
  overflow: hidden;
  @media screen and (max-width: 500px) {
    width: 150px;
    height: 150px;
  }
`
const BgImg2 = styled(Img) `
height: 100%;
width: 100%;
line-height: 300px;
text-align: center;
.span {
display: inline-block;
vertical-align: middle;
line-height: normal;
}
:hover {
    -webkit-transform: scale(1.5,1.5);
    -webkit-transition: all 0.3s ease-in-out;
}
`

const BgImg3 = styled(Img) `
width: 100%;
max-width: 500px;
line-height: 300px;
text-align: center;
.span {
display: inline-block;
vertical-align: middle;
line-height: normal;
}

`


const where = process.env.NODE_ENV === `production` ? '(Prod)' : '(Dev)'

class MenuApp extends React.Component {
    constructor(props) {
        super(props)
        //console.log(JSON.stringify(props))
        if (props.location) {

            console.log(JSON.stringify(props))
        }
        this.state = {
            open: false,
        }
    }

    render() {
        return (
            <div
                tabIndex="0"
                className={`${styles.menu2} ${this.state.open ? `${styles.menuOpen}` : ''}`}
            >
                <span onClick={() => this.close()} role="button" style={{"color":"white" }}>
                    X
               </span>
                <BgImg3 fluid={this.props.image.fluid} backgroundColor={'#eeeeee'}  ></BgImg3>
                <div dangerouslySetInnerHTML={{ __html: this.props.description.childMarkdownRemark.html.replace(/\n/g, "</br>") }}></div>
            </div>
        )
    }

    close() {
        
        this.setState({ open: false })
    }

    open() {
        navigate(
            "/work",
            {
                replace: false,
                state: { open: false }
            }
        )
        this.setState({ open: true })
    }
}

const MenuAppRef = React.forwardRef((props, ref) => {
    const menuRef = useRef()

    useImperativeHandle(ref, () => ({
        open() {
            menuRef.current.open()
        },
    }))

    return <MenuApp ref={menuRef} {...props} />
})

class RecentWork extends React.Component {
    toggleMenu() {
        this.childMenu.open()
    }
    
    render() {
        return (
            <div>
                <DivBgImg2Parent onClick={() => this.toggleMenu()} >
                    <BgImg2 fluid={this.props.image.fluid} backgroundColor={'#eeeeee'}  ></BgImg2>
                </DivBgImg2Parent>
                <MenuAppRef ref={el => (this.childMenu = el)}  {...this.props} />
            </div>
        )
    }
}

export default RecentWork
