import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import CardList from '../components/CardList'
import Card from '../components/Card'
import RecentWork from '../components/RecentWork'
import Helmet from 'react-helmet'
import Container from '../components/Container'
import Pagination from '../components/Pagination'
import SEO from '../components/SEO'
import config from '../utils/siteConfig'
import styled from 'styled-components'
import Img from 'gatsby-image'

const DivBgImg2Parent = styled.div`
  width: 300px;
  height: 300px;
  margin: 10px;
  overflow: hidden;
  @media screen and (max-width: 500px) {
    width: 150px;
    height: 150px;
  }
`

const DivFilter = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
`
const BgImg2 = styled(Img) `
height: 100%;
width: 100%;
line-height: 300px;
text-align: center;
.span {
display: inline-block;
vertical-align: middle;
line-height: normal;
}
:hover {
    -webkit-transform: scale(1.5,1.5);
    -webkit-transition: all 0.3s ease-in-out;
}
`

const FlexContainer = styled.div`
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`

const TextDiv = styled.div`
  padding-top: 20px;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: 20pt;
  font-weight:  bold;
`
class Index extends React.Component {
    constructor(props) {
        super(props);
        const products = props.data.allContentfulRecentWork.edges
        const types = props.data.allContentfulRecentWorkTypes.edges
        this.types = types;
        this.products = products;
        this.typesBool =  types.reduce(function (map, obj) { map[obj.node.id] = true; return map; }, {}) ;
    }

    handleChange(e) {
        //console.log(e.target.checked);
        //console.log(JSON.stringify(this.typesBool));
        this.typesBool[e.target.id] = e.target.checked;
        this.setState({ typesBool: this.typesBool });
    }
    render() {
        return (
            <Layout>
                <SEO />
                {(
                    <Helmet>
                        <title>Portfolio</title>
                    </Helmet>
                )}
                <TextDiv>
                    <span className="accentColor"> Portfolio </span>
                </TextDiv>
                <DivFilter>
                    {this.types.map(({ node: post }) => (
                            <span>
                                <input type="checkbox" id={post.id} name={post.type} checked={this.typesBool[post.id]} style={{ "appearance": "auto" }}
                                    onChange={this.handleChange.bind(this)}
                                ></input>
                                <label for={post.id}>{`${post.type}`}</label>
                            </span>
                    ))
                    }
                </DivFilter>
                <FlexContainer>
                    {this.products.map(({ node: post }) => (
                        <div style={{ textAlign: "center", display: `${this.typesBool[post.recentWorkType.id] ?  "" : "None"}` }}>
                            <RecentWork {...post} />
                        </div>
                    ))}
                </FlexContainer>
            </Layout>
        );
    }
}  
/*
const Index = ({ data, pageContext, location }) => {
    const products = data.allContentfulRecentWork.edges
    const types = data.allContentfulRecentWorkTypes.edges
    const prop = {products:products, types: types, location: location}
    const handleChange = function (e) {
        console.log(e.target.checked);
        console.log(JSON.stringify(typesBool));
        typesBool[e.target.id] = e.target.checked;
    }
    return (
        <RecentWorkMain {...prop} />

    )
}
*/
export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allContentfulRecentWorkTypes {
      edges {
        node{ 
         type      
         id
        }
     }
    }
    allContentfulRecentWork(
      sort: { fields: [updatedAt], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          slug
          updatedAt(formatString: "MMMM DD, YYYY")
          title
          description {
            childMarkdownRemark {
              html
            }
          }
          recentWorkType{
           id
          }
          image {
            title
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`

export default Index